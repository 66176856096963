<template>
    <div class="skillsCompetition-content">
        <el-scrollbar class="scrollbar-list">
            <div class="swiper-container-wrapper">
                <div class="swiper-wrapper">
                    <swiper :auto-update="true" :options="swiperOption">
                        <swiper-slide class="swiper-slide" v-for="(item,index) in imgList" :key="`imgList${index}`">
                            <img :src="item">
                        </swiper-slide>
                    </swiper>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
            <div class="skillsCompetition-wrapper">
                <div class="content-box">
                    <div class="sai-su">
                        <div class="sai-text">
                           <div class="sai-top">
                               <span class="sai-bg"></span>
                               <span class="sai-title">竞赛概述</span>
                           </div>
                            <div class="sai-txt">
                                    一课教育遵循“以赛促教、以赛促学、以赛促改、以赛促建”的指导思想，充分发挥大赛的导向功能。一课自主研发电子商务技能竞赛平台，
                                赛项以电子商务电商装修、店铺运营、客户服务、新媒体运营等典型工作任务的完成质量以及职业素养作为竞赛内容。
                                赛项考察参赛选手职业道德、职业素养、技术技能水平和创业能力，检验电商专业教学成效，促进教学改革。
                            </div>
                        </div>
                        <div class="sai-img">
                            <div class="sai-top">
                                <span class="sai-bg"></span>
                                <span class="sai-title">精彩回顾</span>
                            </div>
                            <swiper :auto-update="true" :options="swiperOption1">
                                <swiper-slide class="swiper-slide" v-for="(item,index) in imgSaiList" :key="`imgList${index}`">
                                    <img :src="item">
                                </swiper-slide>
                            </swiper>
                        </div>
                    </div>
                    <div class="sai-content">
                        <div class="sai-top">
                            <span class="sai-bg"></span>
                            <span class="sai-title">竞赛内容</span>
                        </div>
                        <div class="online-shop-content">
                            <div class="online-shop-item">
                                <div class="online-shop-img">
                                    <img src="../../../assets/images/skillsCompetition/content_1.png" alt="">
                                </div>
                                <div class="online-shop-text">
                                    <p class="online-shop-title">01网店装修</p>
                                    <div class="online-shop-txt">在竞赛平台允许的结构范围内，利用竞赛平台提供的素材，完成网店的开设与基本信息设置；
                                        首页的“店铺横幅、导航、店铺分类、轮播图”的设计与制作，商品的“商品标题、商品主图、商品价格/库存、
                                        商品详情页”的设计与制作。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="online-shop-content">
                            <div class="online-shop-item">
                                <div class="online-shop-text">
                                    <p class="online-shop-title">02网店运营</p>
                                    <div class="online-shop-txt">
                                        包括店铺动态、店铺分析、限时活动、满减活动、聚划算活动、钻石展位。通过店铺动态的设置，吸引顾客的兴趣，刺激顾客的购买欲；
                                        通过对店铺数据的分析， 了解店铺运营的问题所在，并提出相应的优化方案；通过限时活动、满减活动的创建，
                                        提高店铺转化率，提高店铺整体交易额；通过聚划算活动、钻石展位定位精准人群，锁定优质资源位，
                                        为店铺带来优质流量，增加点击率与转化率。
                                    </div>
                                </div>
                                <div class="online-shop-img">
                                    <img src="../../../assets/images/skillsCompetition/content_2.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="online-shop-content">
                            <div class="online-shop-item">
                                <div class="online-shop-img">
                                    <img src="../../../assets/images/skillsCompetition/content_3.png" alt="">
                                </div>
                                <div class="online-shop-text">
                                    <p class="online-shop-title">03网店客户服务</p>
                                    <div class="online-shop-txt">根据竞赛平台买家的提问，及时准确地做好客户服务工作。
                                        通过平台上实时订单的情况去进行修改价格、地址等。
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="online-training">
                        <div class="sai-top">
                            <span class="sai-bg"></span>
                            <span class="sai-title">在线训练</span>
                        </div>
                        <div class="online-training-list">
                            <div class="online-training-item" v-for="(item,index) in onlineTraining" :key="`onlineTraining${index}`">
                                <div class="online-training-top">
                                    <img :src="item.imgUrl" alt="">
                                    <span class="online-training-text">{{item.text}}</span>
                                    <span class="online-training-title">{{item.title}}</span>
                                </div>
                                <div class="online-training-btn">
                                    <a href="http://match.keketong.xyz/#/">
                                        <el-button class="btn-orange" round>进入系统</el-button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="official-footer">
                <OfficialFooter></OfficialFooter>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>

    import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
    import OfficialFooter from '@/components/OfficialFooter'
    import 'swiper/css/swiper.css'
    export default {
        name: "Competition",
        components: {
            Swiper,
            SwiperSlide,
            OfficialFooter
        },
        directives: {
            swiper: directive
        },
        data(){
            return{
                imgList:[
                    require('../../../assets/images/skillsCompetition/index_1.jpg'),
                    require('../../../assets/images/skillsCompetition/index_2.jpg'),
                    require('../../../assets/images/skillsCompetition/index_3.jpg'),
                ],
                imgSaiList:[
                    require('../../../assets/images/skillsCompetition/marvellous_1.jpg'),
                    require('../../../assets/images/skillsCompetition/marvellous_2.jpg'),
                    require('../../../assets/images/skillsCompetition/marvellous_3.jpg'),
                    require('../../../assets/images/skillsCompetition/marvellous_4.jpg'),
                    require('../../../assets/images/skillsCompetition/marvellous_5.jpg'),
                    require('../../../assets/images/skillsCompetition/marvellous_6.jpg'),
                ],
                onlineTraining:[
                    {
                        imgUrl:require('../../../assets/images/skillsCompetition/icon-kefu.png'),
                        text:'网店客户服务',
                        title:'训练售前、售中、售后服务',
                        url:'http://match.keketong.xyz/#/'
                    },
                    {
                        imgUrl:require('../../../assets/images/skillsCompetition/icon-zhuangxiu.png'),
                        text:'网店开设装修',
                        title:'店铺开设、商品采编、网店装修',
                        url:'http://match.keketong.xyz/#/'
                    },
                    {
                        imgUrl:require('../../../assets/images/skillsCompetition/icon-tuiguang.png'),
                        text:'网店推广',
                        title:'模拟大数据分析、运营推广',
                        url:'http://match.keketong.xyz/#/'
                    }
                ],
                //轮播
                swiperOption: {
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false,
                    },
                    loop: false,
                    direction: 'horizontal',
                    slidesPerView: 'auto',

                    paginationClickable: true,
                    observer: true,
                    observeParents: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                },
                swiperOption1:{
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false,
                    },
                    loop: false,
                    direction: 'horizontal',
                    slidesPerView: 'auto',

                    paginationClickable: true,
                    observer: true,
                    observeParents: true,
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.skillsCompetition-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .scrollbar-list{
        height: 100%;
        /deep/ .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .swiper-container-wrapper{
            position: relative;
            .swiper-wrapper{
                height: 478px;
                .swiper-pagination {
                    width: 100%;
                    top: 94%;
                    left: 0;
                    ::v-deep .swiper-pagination-bullet {
                        display: inline-block;
                        width: 30px;
                        height: 13px;
                        border-radius: 7px;
                        margin-right: 10px;
                    }
                }
            }
            ::v-deep .swiper-pagination-bullet-active {
                background: #E21D1A;
            }
            ::v-deep span:focus {
                outline: none;
            }
        }
        .skillsCompetition-wrapper{
            width: 100%;
            min-width: 1200px;
            min-height:800px;
            position: relative;
            overflow: hidden;
            background: #fff url("../../../assets/images/skillsCompetition/bg.jpg")no-repeat bottom center;
            background-size: cover;
            /*background-repeat: no-repeat;*/
            /*!*background: #000;*!*/
            .content-box{
                width: 1200px;
                margin: 90px auto 0;
                position: relative;
                z-index: 5;
                .sai-su{
                    width: 100%;
                    height: 480px;
                    position: relative;
                    .sai-text, .sai-img {
                        width: 412px;
                        height: 372px;
                        padding: 54px 83px;
                        border-radius: 20px;
                        float: left;
                        background: #fff;
                        box-shadow: 0 0 35px 3px rgba(161, 164, 169, 0.21);
                    }
                    .sai-text{
                        margin-right: 44px;
                        .sai-txt{
                            text-indent:2em;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 2;
                            margin-top: 66px;
                        }
                    }
                    .sai-title{
                        display: inline-block;
                        width: 96px;
                        font-size: 24px;
                        color: #333;
                        position: absolute;
                        top: 20%;
                        left: 43.6%;
                        transform: translate(-20%, -43.6%);
                    }
                }
                .sai-img{
                    .sai-title{
                        display: inline-block;
                        width: 96px;
                        font-size: 24px;
                        color: #333;
                        position: absolute;
                        top: 20%;
                        left: 43%;
                        transform: translate(-20%, -43%);
                    }
                }
                .sai-content{
                    margin-top: 170px;
                    .sai-title{
                        display: inline-block;
                        width: 96px;
                        font-size: 24px;
                        color: #333;
                        position: absolute;
                        top:35.8%;
                        left:49%;
                        transform: translate(-35.8%, -49%);
                        z-index: 10;
                    }
                    .online-shop-content{
                        margin-top: 57px;
                        .online-shop-item{
                            display: flex;
                            align-items: center;
                            background: #F2F2F7;
                        }
                        .online-shop-img{
                            width: 324px;
                            height: 292px;
                        }
                        .online-shop-text{
                            flex: 1;
                            padding: 0 76px 0 60px;
                            box-sizing: border-box;
                            .online-shop-title{
                                color: #584EEE;
                                font-size: 24px;
                            }
                            .online-shop-txt{
                                font-size: 16px;
                                color: #333;
                                line-height: 1.5
                            }
                        }
                        &:nth-child(2n+1){
                            .online-shop-item{
                                background: transparent;
                            }
                        }
                    }

                    img{
                        width: 324px;
                        height: 292px;
                    }

                }

                .sai-top {
                    line-height: 44px;
                    position: relative;
                    .sai-bg {
                        display: inline-block;
                        position: relative;
                        width: 100%;
                        height: 20px;
                        line-height: 20px;
                        background-image: url("../../../assets/images/skillsCompetition/title-bg.png");
                        background-repeat: no-repeat;
                        background-position: top center;
                        z-index: 10;
                    }
                }
                .online-training{
                    margin-top: 103px;
                    background: url("../../../assets/images/jingpin-bg.jpg")no-repeat;
                    background-size: cover;
                    padding-bottom: 100px;
                    .sai-title{
                        display: inline-block;
                        width: 96px;
                        font-size: 24px;
                        color: #333;
                        position: absolute;
                        top:35.8%;
                        left:49%;
                        transform: translate(-35.8%, -49%);
                        z-index: 10;
                    }
                    .online-training-list{
                        display: flex;
                        justify-content: space-between;
                        padding: 66px 0;
                        .online-training-item{
                            width: 300px;
                            height: 360px;
                            background: #fff;
                            box-shadow: 0 0 35px 3px rgba(161, 164, 169, 0.21);
                            border-radius: 20px;
                            .online-training-top{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                margin-top: 44px;
                                .online-training-text{
                                    padding: 30px 0 16px 0;
                                    color: #584EEE;
                                }
                            }
                            .online-training-btn{
                                text-align: center;
                                margin: 40px 0;
                                .btn-orange{
                                    background: #F36F21;
                                    color: #fff;
                                    border-radius:30px;
                                    &:focus,
                                    &:hover{
                                        color: #fff;
                                        background: #f28e53;
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
        .official-footer{

        }
    }

}
</style>